<template>
  <div class="body_bg preView_bg manifest_detail" style="padding-top:0.5rem">
    <!-- 货单信息 -->
    <div class="card_box">
      <div class="code">货单编号：{{ shiplistInfo.orderNo }}</div>
      <van-divider />
      <div class="money flex-r-sa-c">
        <div class="item flex-c-c-c">
          <div class="num">{{ shiplistInfo.saleOrderProductList.length }}</div>
          <div class="msg">商品种类</div>
        </div>
        <div class="item flex-c-c-c">
          <div class="num">{{ shiplistInfo.orderPrice }}</div>
          <div class="msg">货单总额</div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="preView_list">
      <div v-for="(item,index) in shiplistInfo.saleOrderProductList" :key="index" class="company_pro_list">
        <div class="flex-r-s-c">
          <proCard08 :info="item" />
        </div>
      </div>
    </div>
    <!-- 底部bar -->
    <div class="bar_box flex-r-c-c">
      <div class="view_back" @click="subconfirmCase">货单下单</div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import proCard08 from '@/components/pro_card_08'
// import MyscrollList from '@/components/MyscrollList'
import { saleOrder_detail, viewAuth } from '@/api/order'
// import { getShoppinglistGoodsDetail, shopping_getShoppinglist } from '@/api/order'
export default {
  components: { proCard08 },
  // components: { MyscrollList, proCard06 },
  data() {
    return {
      // 货单信息
      shiplistInfo: {},
      price: null,
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 20, shopinglistId: '' },
      proList: [],
      listLoading: false
    }
  },
  created() {
    this.shopinglistId = this.$route.query.id
    this.proSearch.shopinglistId = this.$route.query.id
    this.checkIdfindenty()
    // this.getProList()
  },
  methods: {
    checkIdfindenty() { viewAuth().then(res => { if (res.data) { this.getDetail() } else { this.$router.push('/order/shiplistNotWork?type=2') } }) },
    // 获取详情
    getDetail() {
      saleOrder_detail(this.shopinglistId).then(res => {
        if (parseInt(res.data.status) === 1) {
          this.shiplistInfo = res.data
          this.price = parseInt(this.shiplistInfo.orderPrice.replace('.', ''))
        } else {
          this.$router.push('/order/shiplistNotWork?type=1')
        }
      })
    },

    // 提交
    subconfirmCase() {
      this.$router.push(`/order/subOrder?type=5&shopinglistId=${this.shiplistInfo.saleOrderId}`)
    }
  }
}
</script>

 <style lang="scss" scoped>
.body_bg {
  background: #f7f7f7;
}
// 货单详情
.manifest_detail {
  .card_box {
    position: relative;
    .manifest_failed {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

// 预览货单
.preView_bg {
  background: linear-gradient(
    180deg,
    #0767ab 0%,
    #0767abbd 61.08%,
    #0767ab00 100%
  );
  background-size: 100% 6.45rem;
  background-repeat: no-repeat;
  .card_box {
    margin-left: 0.3rem;
    width: 9.37rem;
    height: 2.99rem;
    border-radius: 0.27rem;
    background: #fff;
    .code {
      padding-left: 0.43rem;
      font-size: 0.37rem;
      text-align: left;
      color: #333;

      padding-top: 0.4rem;
    }
    .money {
      .item {
        .num {
          font-weight: 700;
          font-size: 0.43rem;
          color: #333;
        }
        .msg {
          font-size: 0.37rem;
          color: #999;
        }
      }
    }
  }
  .preView_list {
    margin-left: 0.3rem;
    margin-top: 0.21rem;
    margin-bottom: 2rem;
    width: 9.47rem;
    border-radius: 0.27rem;
    background: #fff;
  }
  .bar_box {
    width: 100%;
    height: 1.71rem;
    background: #fff;

    position: fixed;
    bottom: 0rem;
    .view_back {
      width: 8.61rem;
      height: 1.07rem;
      line-height: 1.07rem;
      border-radius: 0.19rem;
      background: #0767ab;
      font-size: 0.37rem;
      text-align: center;
      color: #ffffff;
    }
  }
}
.company_pro_list {
  padding-left: 0.4rem;
}
</style>
